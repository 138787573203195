@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

/* react slick styles */
.slick-next {
  right: 8px !important;
  z-index: 99;
}
.slick-prev {
  left: 8px !important;
  z-index: 99;
}
.slick-track {
  padding-block: 2rem;
}
.slick-arrow::before {
  content: "" !important;
}

.slick-track {
  padding-block: 0;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}
.slick-dots li button:before {
  color: white !important;
}

.centered-list {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.centered-list li:before {
  content: "•";
  margin-right: 0.5em;
}

.centered-list li {
  display: inline-block;
}
